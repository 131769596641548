'use client';

import { useCallback, useRef, useEffect } from 'react';

import { TRACKING_ENABLED, GOOGLE_ANALYTICS_ENABLED } from '../../../config/config';
import useOnNavigate from '../../../hooks/useOnNavigate';
import { trackingPathsToIgnore } from '../TrackingScripts/TrackingScripts';

/**
 * Adds an event to the Google analytics dataLayer on route change.
 */
const DispatchAnalyticsEventOnRouteUpdate = () => {
    const timeout = useRef<NodeJS.Timeout>();

    const trackingEnabled = typeof window !== 'undefined'
        && TRACKING_ENABLED
        && GOOGLE_ANALYTICS_ENABLED
        && trackingPathsToIgnore.includes(window.location.pathname) === false;

    // Adds the event to the dataLayer on mount.
    useEffect(() => {
        if (trackingEnabled === false || typeof window === 'undefined') {
            return;
        }

        // Add the dataLayer if it doesn't exist, add it to the window.
        if (Array.isArray(window.dataLayer) === false) {
            window.dataLayer = [];
        }

        window.dataLayer!.push({
            event: 'gatsby-route-change',
        });
        // This use effect only needs to run on mount.
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Adds the event to the dataLayer on route change.
    const dispatchRoteChangeAnalytics = useCallback(() => {
        if (trackingEnabled === false || typeof window === 'undefined') {
            return;
        }

        clearTimeout(timeout.current);

        // Add the dataLayer if it doesn't exist, add it to the window.
        if (Array.isArray(window.dataLayer) === false) {
            window.dataLayer = [];
        }

        // Wrap inside a timeout to ensure the title has properly been changed.
        timeout.current = setTimeout(() => {
            window.dataLayer!.push({
                event: 'gatsby-route-change',
            });
        }, 50);
    }, []);

    useOnNavigate(dispatchRoteChangeAnalytics);

    return null;
};

export default DispatchAnalyticsEventOnRouteUpdate;
